import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Header from './components/Header';
import About from "./pages/About";
import Contact from "./pages/Contact";
import Features from "./pages/Features";
import Error from "./pages/Error";
import Index from "./pages/Index";
import Footer from './components/Footer';

const Title = "My React Project";

function App() {

    //set html page Title
    document.title = Title;
    
    return (

        <Router>
            
            <Header text={Title}></Header>
            
            <Routes>
                <Route Index="true" path="/*" element={<Index />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/features" element={<Features />} />
                <Route path="/error" element={<Error />} />
            </Routes>

            <Footer></Footer>

        </Router>
        
    );
}

export default App;
