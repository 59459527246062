import React from 'react';
import '../App.css';



function Footer() {
  return (
      <div className="Mario-footer">
         
          <p>Copyright 2022 - SOLMED</p>
         

      </div>
  );
}

export default Footer;