import React from 'react';
import { NavLink } from "react-router-dom";
import '../App.css';



export default function Menu() {

    
   

    return (
 

       
        <div id="right-menu" className="Menu">
            <NavLink to="/" className="Menu_link" activeclassname="active">Home</NavLink>
            <NavLink to="/about" className="Menu_link" activeclassname="active">About</NavLink>
            <NavLink to="/features" className="Menu_link" activeclassname="active">Features</NavLink>
            <NavLink to="/contact" className="Menu_link" activeclassname="active">Contact</NavLink>
        </div>
         
    
    );
}