import React from 'react';
import '../App.css';
import logo from '../logo.svg';
import Menu from '../components/Menu';
import { NavLink } from "react-router-dom";


function Header(props) {
    return (
        <>
            <div className="Mario-header">
                <div className="Header-left">
                    <NavLink to="/" className="Menu_link" activeClassName="active">
                        <img src={logo} width="100" alt="logo" />
                    </NavLink>
                    <p>{props.text}</p>
                </div>
            </div>
            <div className="Header-right">
                <Menu></Menu>
            </div>
            <hr></hr>
        </>

    );
}

export default Header;