import React from 'react';
import logo from '../logo.svg';
import '../App.css';

export default function Index() {
    return (
        <div className="Page">
   
                <h1>Index page</h1>
                
                    <img src={logo} className="App-logo" alt="logo" />
                    <p>
                        Edit <code>src/App.js</code> and save to reload.
                    </p>
                    <a
                        className="App-link"
                        href="https://reactjs.org"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Learn React Mario
                    </a>
        </div>
    );
}
