import React from 'react';

export default function Features() {
    return (
        <div className="Page">
            <h1>Features page</h1>
        </div>

    );
}
