import React from 'react';

export default function About() {
    return (
        <div className="Page">
            <h1>About page</h1>
        </div>

    );
}

